import React, { useState, useEffect, useCallback, useRef } from 'react';
import './modal.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PearsonLogo from '../../../images/pearson_logo.png';
import iconFolder from '../../../images/icon_folder.gif';
import fileIcon from '../../../images/icon_file.gif';

import '../../common/css/pw_push5.css';
import { getSubDirectories, downloadBulkFilesInZip, resetBulkUploadStateManageMent } from '../../Dashboard/actions';
import Loader from "../../common/Loader";
import { updateGenricSuccessMessageToEmpty } from '../../User/actions';
import '../../common/css/downloadprogressbar.css';
import { SOCKET_ENDPOINT } from '../../utils/Constants';

const BulkDownloadPopup = (props) => {
  const [filesLists, setFilesList] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteFilesArray, setDeleteFilesArray] = useState([]);
  const [downloadLoaderVisible, setDownloadLoaderVisible] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState('0%'); // Track download progress
  const [wsTransmittedReceivedData, setWsTransmittedReceivedData] = useState(0); // Track bytes transmitted
  const [netSpeed, setNetSpeed] = useState(0); // Track network speed
  const [timeRemaining, setTimeRemaining] = useState(0); // Estimate remaining time
  const [elapsedTime, setElapsedTime] = useState(0); // Track elapsed time
  const [isDownloading, setIsDownloading] = useState(false); // Track if download is in progress
  const [modalHeight, setModalHeight] = useState('85%'); // State for modal height
  const [progress, setProgress] = useState(0);

  const progressRef = useRef(null); // Reference to progress bar
  const latestProgress = useRef(0); // Store latest progress value
  let [itotal, setITotal] = useState(0);
  const [ws, setWs] = useState(null);

  let currentURI = location.pathname;
  let currentURImatches = currentURI.match(/^\/mdc\/auth\/([^\/]+)/);
  let uriPrefex = currentURImatches ? currentURImatches[0] : '';
  let currentPath = currentURI.replace(`${uriPrefex}`, "");
  currentPath = currentPath.split('/').filter(path => path);
  currentPath = `/${currentPath.join('/')}`;
  if (currentPath === "") {
    currentPath = '/';
  }

  const directoriesList = useSelector(state => state.directoriesReducer.serverResponse);
  const downloadFileLimitMsg = useSelector(state => state.UserReducer.userSuccessMessage);
  const closeBulkDownloadPopup = useSelector(state => state.directoriesReducer.closeDownloadPopup);

  function connectWebSocket(Id) {
    const downloadWs = new WebSocket(`${SOCKET_ENDPOINT}downloadsocket?downloadId=${Id}`);
    setWs(downloadWs);
    downloadWs.onopen = () => {
      console.log("******** Websocket ****** downloadsocket connection established.");
    };
    downloadWs.onmessage = (event) => {
      try {
            const data = JSON.parse(event.data);
            console.log('******** Websocket ****** Message received: ', event.data);
            console.log("******** Websocket ****** Progress received:", data.progress);
            //setProgress(data.progress);

            // Store the latest progress value
            latestProgress.current = data.progress;

            // Update progress bar width immediately
            if (progressRef.current) {
                progressRef.current.style.width = `${latestProgress.current}%`;
            }

        } catch (error) {
            console.error("******** Websocket ******Error parsing WebSocket message:", event.data, error);
        }
    };

  downloadWs.onerror = (error) => {
      console.error("******** Websocket ******WebSocket error:", error);
  };

  downloadWs.onclose = (event) => {
    console.log("WebSocket closed: ", event.code, event.reason);
};
    return () => {
      if (downloadWs) {
        console.log("WebSocket explicit closing: ");
        downloadWs.close();
      }
    };
}


  useEffect(() => {
    if (Object.entries(downloadFileLimitMsg).length > 0) {
      setDownloadLoaderVisible(false);
      props.onModalPopupStateChange(props.displayModal);
    }
    dispatch(resetBulkUploadStateManageMent({}));
  }, [downloadFileLimitMsg]);

  useEffect(() => {
    if (closeBulkDownloadPopup?.status_code == 200) {
      props.onModalPopupStateChange(props.displayModal);
    }
    dispatch(resetBulkUploadStateManageMent({}));
  }, [closeBulkDownloadPopup]);

  useEffect(() => {
    dispatch(updateGenricSuccessMessageToEmpty({}));
    setFilesList(directoriesList?.data);
    deSelectAllFiles();
    setLoading(false);
  }, [directoriesList]);

  useEffect(() => {
    setLoading(true);
    dispatch(getSubDirectories({ dir: currentPath }));
    if (currentPath !== '/') {
      setDisableCheckbox(false);
    } else {
      setDisableCheckbox(true);
    }
  }, [currentPath, dispatch]);

  const deleteFiles = (e) => {
    if (e.target.checked) {
      setDeleteFilesArray([...deleteFilesArray, e.target.value]);
    } else {
      setDeleteFilesArray(deleteFilesArray.filter(file => file !== e.target.value));
    }
  };

  const selectAllFiles = () => {
    let checkboxArray = document.getElementsByName("fileDownload[]");
    let fileArray = [];
    checkboxArray.forEach((file) => { file.checked = true; fileArray.push(file.value) });
    setDeleteFilesArray([...deleteFilesArray, ...fileArray]);
  };

  const deSelectAllFiles = () => {
    let checkboxArray = document.getElementsByName("fileDownload[]");
    checkboxArray.forEach((file) => { setDeleteFilesArray([]); file.checked = false; });
  };

  const closeModal = (e) => {
    e.stopPropagation();
    props.onModalPopupStateChange(props.displayModal);
  };


  const handleDownloadSelected = async () => {
    const pathsToDownload = deleteFilesArray.map((item) => currentPath + '/' + item);
    const encodedPaths = pathsToDownload.map((path) => encodeURIComponent(path));
    const { v4: uuidv4 } = require('uuid');
    if (pathsToDownload.length) {
      setDownloadLoaderVisible(true);
      const Id = uuidv4();
      connectWebSocket(Id);
      setModalHeight('85%');
      dispatch(downloadBulkFilesInZip({ encodedPaths: { encodedPaths, dir: encodeURIComponent(currentPath) ,downloadId: Id} }));
    } else {
      alert('Please select files that you need to download');
    }
  };

  return (
    <>
      <div className="modal" onClick={closeModal}>
      <div className="modal-content-download" style={{ height: modalHeight }} onClick={e => e.stopPropagation()}>
          <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
            <tr align="left" valign="top">
              <td align="left" valign="middle"><span className="pagetitle">&nbsp;Download Files</span></td>
              <td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="85" height="28" hspace="0" vspace="0" border="0" /></td>
              <td><span className="close" onClick={closeModal}>&times;</span></td>
            </tr>
          </table>

          <div className='generalcoursecontainer'>
            <div className="table-container-bulkdownload">
              <table className='myTable' border="1" width="99%" cellSpacing="1" cellPadding="4">
                <tbody>
                  <tr align="left" valign="top">
                    <th align="center" valign="middle" width="60">Download</th>
                    <th align="left" valign="middle"> &nbsp;Name</th>
                    <th align="left" valign="middle" width="100">&nbsp;Size (Bytes)</th>
                    <th align="left" valign="middle" width="120">&nbsp;Last Modified</th>
                  </tr>

                  {loading ? <tr colSpan="8"><Loader /></tr> : (
                    filesLists && filesLists.length > 0 && (
                      filesLists.map((fileList, index) => {
                        let cellStyle = "cellGreen";
                        if (index % 2 === 0) {
                          cellStyle = "cellWhite";
                        }
                        let folderIcon = iconFolder;
                        if (fileList['isFile']) {
                          folderIcon = fileIcon;
                        }
                        return (<tr align="left" valign="top" key={index}>
                          <td width="70" className={"checkboxalign " + cellStyle}>
                            <input type="checkbox" name="fileDownload[]" value={fileList['directory']} disabled={disableCheckbox} onClick={deleteFiles} />
                          </td>
                          <td className={cellStyle} nowrap="">
                            <div className="folderpointer">
                              &nbsp;<img alt={''} src={folderIcon} />&nbsp;{fileList['directory']}
                            </div>
                          </td>
                          <td className={cellStyle}>{fileList['size']}</td>
                          <td className={"checkboxalign " + cellStyle}>{fileList['last_modified_date']}</td>
                        </tr>)
                      })))}
                </tbody>
              </table>
            </div>

            {(!disableCheckbox && localStorage.getItem('user.role') !== 'Vendor') &&
              <table width="100%">
                <tr align="left" valign="top">
                  <td align="left"><button className="cellWhite formButtonGo delete-selected" onClick={selectAllFiles}>Select&nbsp;All</button>
                    <span className="cellWhite">&nbsp;|&nbsp;</span>
                    <button className="cellWhite formButtonGo delete-selected" onClick={deSelectAllFiles}>Clear&nbsp;All</button></td>
                </tr>
                <tr align="left" valign="top">
                  <td><button className="formButtonDownload" onClick={handleDownloadSelected}>Download Selected</button></td>
                </tr>
                {downloadLoaderVisible && (
                  <tr align="left" valign="top">
                    <td style={{ color: 'green' }}>
                      Please wait for a couple of seconds. Your selected files are downloading. If the download is not happening, please allow browser pop-ups for the site.
                    </td>
                  </tr>
                )}
                {downloadLoaderVisible && (
                  <tr align="left" valign="top">
                    <td>
                      <div className="download-progress-container">
                        <div className="download-progress-header">
                          Download is in Progress, Please don't close the window
                        </div>

                          <div className="download-progress-bar-wrapper">
                              {/* <div className="download-progress-bar">
                                <div
                                  className="progress"
                                  style={{ width: `${progress}%` }}
                                ></div>
                              </div>                               */}
                              <div className="download-progress-bar">
                                <div
                                    ref={progressRef}
                                    className="progress"
                                    style={{ width: "0%", height: "100%"}}
                                ></div>
                              </div>
                            </div>
                          <div className='loader-container'>
                          {latestProgress.current !== 100 ? <Loader /> : ""}
                          </div>
                      </div>
                    </td>
                  </tr>
                )}
              </table>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkDownloadPopup;